$(function(){
    $(".filter-button").click(function(){
      let selectedCat = $(this).data("cat");
      var galleryItems = $('div[data-cat]');
      galleryItems.hide('fast');
      $.each(galleryItems, function(){
        if (selectedCat == $(this).data("cat")) {
          $(this).toggle('fast');
        }
      });
    });
    $(".show-all-button").click(function(){
	  var galleryItems = $('div[data-cat]');
      galleryItems.show('fast');
    });
});
